import type {AsyncData} from '#app';

export const useEtherPage = ({ error, data, pending }: AsyncData<any, any>, entryKey: string = 'entry') => {
    const craftEntry = computed(() => {
        return data?.value?.[entryKey];
    });

    watchEffect(() => {
        if (pending.value) {
            // Don't do anything while loading
            return;
        }
        if (!craftEntry?.value && unref(error)) {
            showError({
                statusCode: error?.value?.statusCode || -1,
                statusMessage: error.value?.statusMessage || 'Internal server error',
                message: error.value?.toString(),
            });
        } else if (!craftEntry?.value) {
            showError({ statusCode: 404, statusMessage: 'Page Not Found' });
        }
    });

    return craftEntry;
};
